<template>
  <div id="membershipVisitors">
    <header class="visitorsHeader">
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <p
        class="visitorsTitle"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipVisitors.visitors =
            $event.target.innerText
        "
      >{{ $t($i18n.locale).keys.membershipArea.membershipVisitors.visitors }}</p>
      <div class="visitorsDataWrapper">
        <div
          class="visitorsQuantity"
          v-if="$store.getters.visitorsQuantity > 0"
        >{{ $store.getters.visitorsQuantity }}</div>
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'eye-green-icon.svg')" alt="Visitors icon" />
      </div>
    </header>
    <div class="visitorsImagesWrapper" :class="{'leftAlign': visitorList.length == 2}" v-if="$store.getters.visitorsQuantity != 0">
      <div
        @click="$router.push(`member-profile/profile?id=${visitor.profile_id}`);"
        v-for="visitor in visitorList"
        :key="visitor.id"
        class="image1Wrapper"
      >
        <img
          :src="`${visitor.public_path}`"
          alt=""
          class="visitorImage1"
          v-if="visitor.public_path"
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
          class="visitorImage1"
          v-if="!visitor.public_path && (parseInt(visitor.gender) == 1)"
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
          class="visitorImage1"
          v-if="!visitor.public_path && (parseInt(visitor.gender) == 2)"
        />
        <div
          class="imageOverlay"
          v-bind:class="[visitor.new == 1 ? 'new' : '']"
        >
          <p
            v-if="visitor.new == 1"
            class="newMessage"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipVisitors.new =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipVisitors.new }}
          </p>
          <div class="wrapper">
            <p class="visitorName">
              {{ visitor.firstname.length > 12 ? visitor.firstname.substring(0, 13).concat('...') : visitor.firstname }}
            </p>
            <div class="wrapperInside">
              <!--
              <img v-if="parseInt(visitor.entertainment) == 1" src="../../assets/images/heart-green.svg" alt="Heart icon">
              -->
              <p class="visitorAgeLocation">
                {{ visitor.age }}<span v-if="visitor.city">, {{ visitor.city.length > 12 ? visitor.city.substring(0, 9).concat('...') : visitor.city }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="membershipButtonWrapper" v-if="$store.getters.visitorsQuantity != 0">
       <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <router-link to="/membership-area/visitors">
        <button
          class="membershipButton"
          v-langErr :contenteditable="editable"
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.membershipVisitors.seeAllVisitors =
              $event.target.innerText
          "
        >
          {{
            $t($i18n.locale).keys.membershipArea.membershipVisitors
              .seeAllVisitors
          }}
        </button>
      </router-link>
                  <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.membershipVisitors.seeAllVisitors
          "
          type="text"
        />
    </div>
    <!--
    <div class="visitorsFreeAccountWrapper" v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')">
      <div class="contentWrapper">
        <img src="../../assets/images/no-views.svg" alt="No meetings icon" />
        <p class="becomePremiumText">
          <span
            class="text1"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1 =
              $event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1}}</span>
          <br />
          <span
            class="text2"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2 =
              $event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2}}</span>
        </p>
        <div
          class="premiumButtonWrapper"
        >
          <a href="/premium-plans" class="premiumButton">
            <span
              v-langErr
              :contenteditable="editable"
              @blur="
            $t($i18n.locale).keys.membershipArea.membershipHeader.become =
              $event.target.innerText
          "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.become
              }}
            </span>
            <span
              class="headerPremiumMember"
              v-langErr
              :contenteditable="editable"
              @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.membershipHeader.premiumMember =
              $event.target.innerText
          "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipHeader.premiumMember
              }}
            </span>
          </a>
        </div>
      </div>
    </div>
    -->

    <div class="noVisitorsWrapper" v-if="$store.getters.visitorsQuantity == 0">
      <div class="contentWrapper">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'no-visitors-icon.svg')" alt="No visitors icon">
        <p class="noVisitorsText">
          <span
          class="text1"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3 =
                $event.target.innerText
            "
          >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3}}</span><br>
          <span
          class="text2"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4 =
                $event.target.innerText
            "
          >{{$t($i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4}}</span>
        </p>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MembershipVisitors",
  components: {},
  data() {
    return {
      publicPath: process.env.VUE_APP_URL
    };
  },
  computed: {
    ...mapGetters(["editable", "isMobile"]),
    visitorList: function() {
      let visitors = this.$store.getters.getHomeVisitors
      if(this.isMobile) return visitors
      else return visitors.slice(0, 3)

    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
