
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

const BASE_URL = process.env.VUE_APP_URL;


const Celeris_helpers = {

   async _initialize(data, context){
      console.log('initCeleris');
      try {
        const response = await context.$axios.get(BASE_URL + `/api/payments/CelerisPay/hosted_fields/${context.getActivePlan.plansPrice.planId}`)      
        console.log('initCeleris setup', response);

        var interactionClasses = {
          isDirty: "WLP-HF-Dirty",
          isEmpty: "WLP-HF-Empty",
          isFocused: "WLP-HF-Focused",
          isTouched: "WLP-HF-Touched",
          isValid: "WLP-HF-Valid",
        };

        var initializationOptions = new hostedFieldsSdk.InitializeOptions()
        .setIFrameSrc(response.data.data.url)
        .setRequestID(response.data.data.requestID)
        .setField(
          hostedFieldsSdk.Fields.CARD_CVV,
          new hostedFieldsSdk.FieldOptionsClass()
            .setContainerID("CARD_CVV-ContainerID")
            .setPlaceholder(context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.CID'))
            .setInteractionClasses(interactionClasses)
            .setStyles({
                            'font-size': '20px',
                            'line-height': '1.25em',
                            'placeholder-color': '#313234',
                            'color': '#313234'
            })
          )
          .setField(
            hostedFieldsSdk.Fields.CARD_NUMBER,
            new hostedFieldsSdk.FieldOptionsClass()
              .setContainerID("CARD_NUMBER-ContainerID")
              .setPlaceholder(context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.cardNumber'))
              .setInteractionClasses(interactionClasses)
              .setStyles({
                'font-size': '20px',
                'line-height': '1.25em',
                'placeholder-color': '#313234',
                'color': '#313234'
              })
          )
          .setField(
            hostedFieldsSdk.Fields.CARD_HOLDER_NAME,
            new hostedFieldsSdk.FieldOptionsClass()
              .setContainerID("CARD_HOLDER_NAME-ContainerID")
              .setPlaceholder(context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.cardHolder'))
              .setInteractionClasses(interactionClasses)
              .setStyles({
                'font-size': '20px',
                'line-height': '1.25em',
                'placeholder-color': '#313234',
                'color': '#313234'
              })
          )
          .setField(
            hostedFieldsSdk.Fields.EXPIRY_FIELD,
            new hostedFieldsSdk.FieldOptionsClass()
              .setContainerID("EXPIRY_FIELD-ContainerID")
              .setPlaceholder(context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.expireCombined'))
              .setInteractionClasses(interactionClasses)
              .setStyles({
                'font-size': '20px',
                'line-height': '1.25em',
                'placeholder-color': '#313234',
                'color': '#313234'
              })
          );

        const sdkInstance = await hostedFieldsSdk.initialize(initializationOptions);
        //sdkInstance.setAutoFocus(false);
        context.sdkInstance = sdkInstance;

      }catch(error) {
        console.log('initCeleris setup error', error);
      }

   },

   _validation(data, context){
      console.log('celerisValidate', data);
       
      return new Promise((resolve, reject) => {
        
        context.sdkInstance.getHostedFieldsState(function (state) {
          var isValid = true;
          var keys = Object.keys(state);
          for (var i = 0; i < keys.length; i++) {
              var fieldState = state[keys[i]];
              isValid = isValid && fieldState.isValid;
          }
          if (isValid) {
              // Perform tokenization
              console.log('Form is valid! Submitting form...');
              resolve(true);
          } else {
              // Show error as iFrame fields are not valid
              console.log('Form is not valid! Please enter valid details!!');
              context.celerisLocked = false;
              resolve(false);
          }
      })

      });
        

   }, 

   _tokenize(data, context){
      console.log('tokenizeCeleris')

     
   },

   async _submitData(data, context){
     
    if(context.celerisLocked) return false;
    
    context.celerisLocked = true;
    let isValid = await Celeris_helpers._validation(data, context);
    console.log('isValid', isValid);
    if(!isValid) return false;


    var evt = data.evt;
    console.log('submitCeleris', evt)
    var form = evt.target;
    form.elements['submitButton'].disabled = true;

    context.sdkInstance.requestTokenization().then(successResponse => {
      console.log('tokenizeCeleris result', successResponse);

      var paymentData = successResponse;
    
      if(context.isMobile){
        console.log('showSummaryCeleris send')
        context.$emit('showSummary', {data: paymentData, form: form});
        
      }else{
        Celeris_helpers._finalizePayment({paymentData: paymentData}, context)
      }

    }).catch(err => {
      console.log('tokenizeCeleris error', err);
      context.celerisLocked = false;
    })

   },

   _finalizePayment(data, context){

    console.log('finalizeCeleris', data);

    if(data.isSummary) $('#mobileSubmitBtn').prop('disabled', true);

        // Process the successful response

        context.$axios.post(BASE_URL + `/api/payments/CelerisPay/subscribe/${context.getActivePlan.plansPrice.planId}`, 
          {'token': data.paymentData.tokenID}
        ).then((response) => {
          console.log('Celeris finalize response', response)
          if(response.data.data.action){
            /*
            context.$axios.post(response.data.data.action, {'data': response.data.data.value}).then((resp) => {
              console.log('Celeris finalize resp', resp)
            })
              */

            let form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', response.data.data.action);
            let input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'data');
            input.setAttribute('value', response.data.data.value);
            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            
          }
        }).catch((error) => {
          console.log('Celeris finalize error', error)
          let errorMessage = '';
          if(error.response.data.data && error.response.data.data.error == "payment attempt not allowed"){

            //reset attempt counter
            context.countPaymentAttempt = 1;
    
            // Display error message
            errorMessage = context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.errorMaxAttempts');
          }else if(error.response.data.data){
            errorMessage = error.response.data.data.error;
          }else{
            console.error(error);
          }
    
          if(errorMessage){
            context.paymentErrors = errorMessage;
          }

          context.celerisLocked = false;
          if(data.isSummary) $('#mobileSubmitBtn').prop('disabled', false);
        })
/*
            success: function (response)
            {
                $('body').append(
                    $('<form>').attr('action', response.action).attr('method', 'post').attr('id','celerispayform').append(
                        $('<input>').attr('type','hidden').attr('name', 'data').val(response.value)
                    )
                )

                $('#celerispayform').submit();
            }
                */
        

   }
   
}

export default Celeris_helpers