<template>
  <div id="membershipMatches">
    <header class="matchesHeader">
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <p
        class="matchesTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipMatches.matches =
            $event.target.innerText
        "
      >
        {{ $t($i18n.locale).keys.membershipArea.membershipMatches.matches }}
      </p>
      <div class="matchesDataWrapper">
        <div class="matchesQuantity">{{ $store.getters.matchesQuantity }}</div>
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-green-icon.svg')"
          alt="Matches icon"
        />
      </div>
    </header>
    <div class="matchesImagesWrapper" :class="{'leftAlign': matchesList.length == 2}">
      <div
        @click="
               $router.push(`member-profile/profile?id=${match.profile_id}`);
        "
        v-for="match in matchesList"
        :key="match.id + 'match'"
        class="image1Wrapper"
      >
        <img
          :src="`${match.public_path}`"
          class="matchesImage1"
          v-if="match.public_path"
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
          alt=""
          class="matchesImage1"
          v-if="!match.public_path && (parseInt(match.gender) == 1)"
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
          alt=""
          class="matchesImage1"
          v-if="!match.public_path && (parseInt(match.gender) == 2)"
        />
        <div class="imageOverlay" v-bind:class="[match.new == 1 ? 'new' : '']">
          <p
            v-if="match.new == 1"
            class="newMessage"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipMatches.new =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipMatches.new }}
          </p>
          <div v-if="match.firstname" class="wrapper">
            <p class="matchInfo">
              <span class="matchName">{{ match.firstname }}</span>
              <span class="matchAge">, {{ match.age }}</span>
            </p>
            <!--
            <div class="insideWrapper">
              <img v-if="parseInt(match.entertainment) == 1" src="../../assets/images/heart-green.svg" alt="Heart icon">
              <p class="matchAgeLocation">, {{ match.city.length > 12 ? match.city.substring(0, 9).concat('...') : match.city }}</p>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="membershipButtonWrapper">
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        src="../../assets/images/Translate-Icon.svg"
        alt="Translation icon"
      />
      <router-link to="/membership-area/matches">
        <button class="membershipButton">
          {{
            $t($i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches
          }}
        </button>
      </router-link>
      <input
        v-if="editable"
        v-model="
          $t($i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches
        "
        type="text"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MembershipMatches",
  components: {},
  computed: {
    ...mapGetters(["editable", "isMobile"]),
    matchesList: function() {
      let matches = this.$store.getters.getHomeMatches
      if(this.isMobile) return matches
      else return matches.slice(0, 3)
    }
  },

  data() {
    return {
      publicPath: process.env.VUE_APP_URL
    };
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped></style>
